import * as React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogContainer = ({ posts }) => {
  return (
    <div className="relative bg-gray-50 pb-20 px-2 pt-8 sm:px-6 lg:pb-28 lg:px-8">
      <div className="absolute inset-0">
        <div className="bg-white h-1/3 sm:h-2/3" />
      </div>
      <div className="relative max-w-7xl mx-auto">
        <div className="max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
          {posts.map((post) => (
            <div
              key={post.node.frontmatter.id}
              className="flex flex-col rounded-lg shadow-lg overflow-hidden"
            >
              <div className="flex-shrink-0">
                <GatsbyImage
                  className="h-48 w-full object-cover"
                  image={getImage(post.node.frontmatter.imageUrl)}
                  alt=""
                />
                <div
                  style={{
                    background: `linear-gradient(70deg, rgb(14, 190, 255), rgb(255, 221, 64), rgb(174, 99, 228), rgb(71, 207, 115))`,
                    height: `3px`,
                  }}
                ></div>
              </div>
              <div className="flex-1 bg-white p-4 flex flex-col justify-between">
                <div className="flex-1">
                  <Link to={post.node.frontmatter.slug} className="block">
                    <p className="text-xl font-semibold text-purple-600">
                      {post.node.frontmatter.title}
                    </p>
                  </Link>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex-shrink-0">
                    <span>
                      <span className="sr-only">
                        {post.node.frontmatter.author}
                      </span>
                      <GatsbyImage
                        className="h-10 w-10 rounded-full"
                        image={getImage(post.node.frontmatter.authorImg)}
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900">
                      {post.node.frontmatter.author}
                    </p>
                    <div className="flex space-x-1 text-sm text-gray-500">
                      <time dateTime={post.node.frontmatter.datetime}>
                        {post.node.frontmatter.date}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogContainer;
