import * as React from "react";
import { graphql } from "gatsby";
import BlogContainer from "../components/BlogContainer";
import CenteredHeading from "../components/CenteredHeading";
import Seo from "../components/Seo";

const BlogPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  return (
    <>
      <Seo title="Blog" />
      <CenteredHeading pageTitle="Blog" mainText="What we've been working on" />
      <BlogContainer posts={edges} />
    </>
  );
};

export default BlogPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            author
            authorImg {
              childImageSharp {
                gatsbyImageData(width: 40, height: 40, quality: 100)
              }
            }
            imageUrl {
              childImageSharp {
                gatsbyImageData(width: 420, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`;
